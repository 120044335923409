import { Component, OnInit } from '@angular/core';
import { LoginService} from '../login.service';
import { MessageService } from '../message.service';
import { HttpErrorResponse} from '@angular/common/http';
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

	public loginData = {username: "", password: "", vehiculo: "", access_token:""};
   public vehicles: string[];
   public options = ['var1', 'var2', 'var3'];

  constructor(private loginService: LoginService, private messageService: MessageService, private router: Router) { }

   login() {
      if (this.loginData.vehiculo)  {
        this.loginService.obtainAccessToken(this.loginData).subscribe(data => this.saveToken(data));
      }
      else{
        this.messageService.add("Debe elegir una organizacion.");
      }
   } 	

   ngOnInit() {
      this.getToken();
   }

   saveToken(token) {
      this.loginService.saveToken(token, this.loginData.username);
      this.loginData.access_token = token.access_token;  
      this.loginData.password     = "";
      this.router.navigate(['/']);
      this.loginService.setLogged(this.loginData);
      this.loginService.setMenuSelected("");
   }

   logout(){
      this.loginService.logout();
      this.loginData.access_token = "";
      this.loginData.username     = "";
      this.loginService.setLogged(this.loginData);
   }

   getToken(): void {
      this.loginData.access_token = this.loginService.getToken();
      this.loginData.username     = this.loginService.getUsername();
      this.loginService.setLogged(this.loginData);
   }

   getVehicles(): void {
      this.loginService.getVehicles(this.loginData.username).subscribe(vehicles => {this.vehicles = vehicles;
                                                                                    console.log("vehicles:" + this.vehicles[1])
                                                                                    });
   }
}