import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from './login/login.component';
import {PositionComponent} from './position/position.component';
import {FlowsComponent} from './flows/flows.component';
import {AuthGuardService} from './auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: 'login', component: LoginComponent},
  { path: 'position', component: PositionComponent, canActivate: [AuthGuardService]},
  { path: 'flows', component: FlowsComponent, canActivate: [AuthGuardService]}

];

@NgModule({
	imports: [ RouterModule.forRoot(routes, { useHash: true }) ],
  	exports: [ RouterModule ]
})
export class AppRoutingModule {}