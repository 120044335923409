import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login.service';

@Component({
  	selector: 'app-menu',
  	templateUrl: './menu.component.html',
  	styleUrls: ['./menu.component.css']
})

export class MenuComponent implements OnInit {

	public isLogged     = false;
	public selectedMenu = "";
	public loginData    = {username: "", password: "", vehiculo: "", access_token:""};

  	constructor(private loginService: LoginService) { }

  	ngOnInit() {

  		this.loginData.access_token = this.loginService.getToken();
      this.loginData.username     = this.loginService.getUsername();
      this.isLogged               = this.loginData.access_token?true:false;

  		this.loginService.change.subscribe(isLogged => {
      	this.isLogged = isLogged;
    	}); 

    	this.loginService.changeMenu.subscribe(selectedMenu => {
      	this.selectedMenu = selectedMenu;
    	}); 
  	}

  	onSelect(e){
  		this.selectedMenu = e;
  	}
}