<article class="form ms-font-m">

   <h3 class="form_title ms-font-xxl ms-fontWeight-regular">Flujos</h3>
   
   <div class="form-group">
      <label for="username" class="form_label ms-font-m">Fecha</label>
      <div class="input-group">
         <input class="form-control ms-font-m" placeholder="dd-mm-yyyy" name="dp" #c="ngModel" [(ngModel)]="dateProc" ngbDatepicker #d="ngbDatepicker" >
         <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
         </div>
      </div>
   </div>

   <div class="text-right">
      <button class="form_btn ms-font-xs" (click)="getFlows(c.status)" type="submit">Descargar Datos</button>
   </div>
</article>
