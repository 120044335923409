import { Component, OnInit } from '@angular/core';
import { PositionService} from '../position.service';
import { MessageService } from '../message.service';
import { LoginService} from '../login.service';
import { FormaterService} from '../formater.service';
import {Router} from "@angular/router";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';


declare const Excel: any;
declare const Office: any;

@Component({
  selector: 'app-position',
  templateUrl: './position.component.html',
  styleUrls: ['./position.component.css']
})
export class PositionComponent implements OnInit {
  public dateProc;
  WORKSHEETNAME="DK-POSICION";

  constructor(private positionService: PositionService, private messageService: MessageService, private formaterService: FormaterService, private router: Router) { }
  	ngOnInit() {
  }

   getPosition(status): void {
  	console.log("Entrado a getPosition...");
  	if (this.dateProc) { 
  			if (status=="VALID") {
	 	  		console.log(status);
		  		console.log(this.dateProc);
		  		var dateParam = this.formaterService.formatDateToYYYYMMDD(this.dateProc);
		  		console.log(dateParam);
			  	this.positionService.getPosition(dateParam)
			  					.subscribe(resp =>  {console.log("largo respuesta:" + resp.length);
			  										 this.fillTable(resp, this.formaterService, this.WORKSHEETNAME);
			  										 console.log("Despues de fillTable...");
			  										});
		  	}
		  	else {
		  	   this.messageService.add("Formato de la fecha inválido.");
		  	}
  	}
  	else {
  		   this.messageService.add("Debe indicar una fecha.");
  	}
  	console.log("Saliendo de getPosition...");
  }

   async fillTable(resp, formater, worksheetName) {
  	console.log("Entrando a fillTable...");
  	await this.createWorksheet(this.WORKSHEETNAME);
  	await this.clearDataSheet(this.WORKSHEETNAME);
  	if (resp.length>0) {  
	   	Excel.run(function(context) {
	   	console.log("Antes de worksheets.getItem");
	    var sheet = context.workbook.worksheets.getItem(worksheetName);
	    console.log("Despues de worksheets.getItem");
	    var largo = resp.length+1;
	    console.log("Largo:"+largo);

	    var rangeSize = "A1:S"+largo;
	    
      	console.log("Rango:"+rangeSize);
      	var titles=[["Fecha", "Ambiente de Precio",  "Producto", "Deal Id", 
	    											 "Cliente", "Buy/Sell", "Moneda", "Monto","Moneda Base",
	    											 "Monto Moneda Base", "Contra Moneda", "Contra Monto",
	    											 "Quote Id", "Quote Value", "Plazo Residual", "Bucket",
	    											 "Deal Date", "End Date", "Signo"]];

	    var json2values= titles.concat(resp.map(item =>
	    		[formater.formatEpochToDate(item.dateProc), item.priceEnvironment.name, item.product.name, item.dealId,
	    		item.client.name, item.buySellType, item.currency.id, item.ammount, item.priceEnvironment.currency.id,
	    		item.baseAmmount, item.contraCurrency.id, item.contraAmmount,
	    		item.quoteId, item.quoteValue, item.daysToMaturity, item.bucket.buquetLabel,
	    		formater.formatEpochToDate(item.dealDate),formater.formatEpochToDate(item.endDate),  Math.sign(item.ammount)
	    		]
    	));

	    var range = sheet.getRange(rangeSize);
      	range.values=json2values


	    if (Office.context.requirements.isSetSupported("ExcelApi", 1.2)) {
	        sheet.getUsedRange().format.autofitColumns();
	        sheet.getUsedRange().format.autofitRows();
	    }
	    sheet.activate();

	    // Convert the range to a table
	    var positionTable = sheet.tables.add(rangeSize, true);
	    positionTable.name = "PositionTable";

    	return context.sync();
		})
	}
	else {  
		this.messageService.add("Respuesta vacía");
	}
	console.log("Terminando filltable 3...");
  }

  async createWorksheet(worksheet){
	await Excel.run(async (context) =>{
  	console.log("Entrando a createWorksheet...");
    var sheets = context.workbook.worksheets;

    var sheet = sheets.add(worksheet);
    sheet.load("name, position");

 	console.log("Saliendo a createWorksheet...");

    await context.sync()
        .then(function () {
            console.log(`Added worksheet named "${sheet.name}" in position ${sheet.position}`);
        });
	}).catch(function(){console.log(worksheet+" ya existe");});

}

async clearDataSheet(worksheet) {
	await Excel.run(async (ctx) => {
		console.log("Entrando a clearDataSheet");
	    var sheet = ctx.workbook.worksheets.getItem(worksheet);
	    sheet.getRange().clear();
   		console.log("Saliendo de clearDataSheet");
	    await ctx.sync();
	})
}

}
