import { Injectable, Output, EventEmitter } from '@angular/core';
import { Cookie } from 'ng2-cookies';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { MessageService } from './message.service';
import { throwError } from 'rxjs';
import { filter } from 'rxjs/operators'
import { AppSettingsService } from './app-settings.service';

export class Foo {
   constructor(
      public id: number,
      public name: string) { }
} 

@Injectable({
  providedIn: 'root'
})

export class LoginService {

   public isLogged     = false;
   public selectedMenu = "";
   private loginUrl    = AppSettingsService.settings.loginUrl;
   //private loginUrl = 'https://tblocks-oauth-server.cfapps.io';  // URL to web api

   constructor(private _http: Http,  private messageService: MessageService){}

   @Output() change: EventEmitter<boolean> = new EventEmitter();
   @Output() changeMenu: EventEmitter<string> = new EventEmitter();

   setLogged(loginData) {
      this.isLogged = loginData.access_token?true:false;
      this.change.emit(this.isLogged);
   }

   setMenuSelected(menu) {
      this.selectedMenu = menu;
      this.changeMenu.emit(this.selectedMenu);
   }

   obtainAccessToken(loginData) {
      console.log("Obteniendo token...");
      let params = new URLSearchParams();
      console.log("Estableciendo parámetros...");
      params.append('username',loginData.username);
      params.append('password',loginData.password);    
      params.append('grant_type',AppSettingsService.settings.grantType);
      params.append('client_id',AppSettingsService.settings.clientId);
      params.append('organization_id',loginData.vehiculo);
      console.log("Estableciendo headers...");
      let headers = new Headers({'Content-type': 'application/x-www-form-urlencoded; charset=utf-8', 'Authorization': 'Basic '+btoa(AppSettingsService.settings.clientId+":"+AppSettingsService.settings.clientSecret)});

      let options = new RequestOptions({ headers: headers });
      const url =  this.loginUrl+'/uaa/oauth/token';
      console.log("Invocando el servicio de token...");
      return this._http.post(url, params.toString(), options)
                  .pipe(map(res => res.json()), 
                  catchError((res:Response) => this.onError(res) )); 
   }

   onError(res: Response) {
      const statusCode = res.status;
      const error = {
         statusCode: statusCode,
         error: "",
         error_description: ""
      }

      if (res.status==400) {
         const body  = res.json();
         error.error =  body.error;
         error.error_description = body.error_description;
      }
      else {
         console.log("res: "+ res);
         error.error= res.statusText;
         error.error_description = "Error de comunicación con el servicio de autorización";
      }
      this.messageService.add(error.error_description);  

      return throwError(error);
   }

   saveToken(token, username){
      console.log("Guardando token...");
      var expireDate = new Date().getTime() + (1000 * token.expires_in);
      Cookie.set("access_token", token.access_token, expireDate);
      Cookie.set("username", username, expireDate);
      console.log("Token guardado...");
   }

   getResource(resourceUrl) : Observable<Foo>{
      var headers = new Headers({'Content-type': 'application/x-www-form-urlencoded; charset=utf-8', 'Authorization': 'Bearer '+Cookie.get('access_token')});
      var options = new RequestOptions({ headers: headers });
      return this._http.get(resourceUrl, options)
                  .pipe(map((res:Response) => res.json()),
                  catchError((error:any) => Observable.throw(error.json().error || 'Server error')));
   }

   /*
   checkCredentials(){
      if (!Cookie.check('access_token')){
         this._router.navigate(['/login']);
      }
   } 
   */

   getToken() : string {
      if (Cookie.check('access_token')){
         return Cookie.get("access_token");
      }
      else {
         return "";
      }
   }

   getUsername() : string {
      if (Cookie.check('username')){
         return Cookie.get("username");
      }
      else {
         return "";
      }
   }

   logout() {
      Cookie.delete('access_token');
      Cookie.delete('username');
   }

   getVehicles (username): Observable<string[]> {
      const url = this.loginUrl+'/uaa/vehicles/'+username;

      return this._http.get(url)
                  .pipe(map(res => res.json()), 
                  catchError((res:Response) => this.onError(res) )); 
   }
}