import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { MessageService } from './message.service';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { Cookie } from 'ng2-cookies';
import { OnInit } from '@angular/core';
import { AppSettingsService } from './app-settings.service';


@Injectable({
  providedIn: 'root'
})
export class PositionService  {

 	constructor(private _http: Http,  private messageService: MessageService){}



  onError(err) {
    this.messageService.add('Error en comunicación con servicio de posición');  
    return throwError(err);
 }

  getPosition (dateProc): Observable<any> {
    const url =  AppSettingsService.settings.apiUrl+'/load-bi/position/'+dateProc;
    var headers = new Headers({'Authorization': 'Bearer '+Cookie.get('access_token')});
    var options = new RequestOptions({ headers: headers });
    return this._http.get(url, options)
       .pipe(map(res => res.json()), 
       catchError(err => this.onError(err)) ); 
  }  
}
