<article>
	<section *ngIf="!loginData.access_token" class="form ms-font-m">

		<h3 class="form_title ms-font-xxl ms-fontWeight-regular">Inicio de Sesión</h3>
		
		<div>
			<label for="username" class="form_label ms-font-m">Usuario</label>
			<input id="username" class="form_field ms-font-m" type="text" [(ngModel)]="loginData.username" (change)="getVehicles()"/>
		</div>

		<div>
			<label for="password" class="form_label ms-font-m">Contraseña</label>
			<input id="password" class="form_field ms-font-m" type="password"  [(ngModel)]="loginData.password"/>
		</div>
		
		<div>
			<label for="vehiculo" class="form_label ms-font-m">Vehículo Legal</label>
			<select id="vehiculo" class="form_field ms-font-m" [(ngModel)]="loginData.vehiculo">
				<option value="">Seleccione</option>
				<option *ngFor="let o of vehicles" [value]="o">{{o}}</option>
			</select>
		</div>
		
		<div class="text-right">
			<button class="form_btn ms-font-xs" (click)="login()" type="submit">Enviar</button>
		</div>
	</section>

	<section *ngIf="loginData.access_token" class="form ms-font-m">

		<h3 class="form_title ms-font-xxl ms-fontWeight-regular">Cuenta</h3>
		<div>
			<label for="username" class="form_label ms-font-m">Usuario</label>
			<input id="username" class="form_field ms-font-m" [(ngModel)]="loginData.username" type="text" readonly>				  			
		</div>

		<div class="text-right">
			<button class="form_btn ms-font-xs" (click)="logout()" type="submit">Cerrar Sesión</button>
		</div>
	</section>

</article>