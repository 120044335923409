import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms'; // <-- NgModel lives here
import { HttpModule } from '@angular/http';
import { MessagesComponent } from './messages/messages.component';
import { AppRoutingModule } from './/app-routing.module';
import { PositionComponent } from './position/position.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgbDateCustomParserFormatterService} from './ngb-date-custom-parser-formatter.service';
import { NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { APP_INITIALIZER } from '@angular/core';
import { AppSettingsService } from './app-settings.service';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/es-419';
import { MenuComponent } from './menu/menu.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FlowsComponent } from './flows/flows.component';

registerLocaleData(localeFr, 'es-419');

export function initializeApp(appSettingsService: AppSettingsService) {
  console.log("Inicializando...");
  return () => appSettingsService.load();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MessagesComponent,
    PositionComponent,
    MenuComponent,
    FlowsComponent
  ],            
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    AppRoutingModule,
    NgbModule,
    AngularFontAwesomeModule
  ],
  providers: [ { provide: LOCALE_ID, useValue: 'es-419' } ,
                {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatterService},
                AppSettingsService,
                {  provide: APP_INITIALIZER,
                   useFactory: initializeApp,
                   deps: [AppSettingsService], multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
