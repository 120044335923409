import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'; 
import { LoginService } from './login.service';
import { MessageService } from './message.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{

  constructor(private router: Router, private loginService : LoginService, private messageService: MessageService) { }

   canActivate(): boolean {
        if(!this.loginService.getToken()){
            // user is invalid. redirect to unauthorized route
            console.log("Redirigiendo a login....");
            this.messageService.add("Usuario no logeado");
            this.router.navigate['login'];
            return;
        } else {
            // user is valid, continue to route
            return true;
        }
    }

}
