import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppSettings } from "./app-settings";
import { Http, Response } from '@angular/http';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { MessageService } from './message.service';
import { throwError } from 'rxjs';




@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
	static settings: AppSettings;
  

  constructor(private http: Http, private messageService: MessageService) {
  }
 /*
  getSettings(): Observable<AppSettings> {   
    return this.http.get("/src/app/assets/appsettings.json")
       .pipe(map(res => res.json()), 
       catchError(err => this.onError(err)) ); 
  }
*/
  onError(err) {
    this.messageService.add('Error obteniendo la configuración');  
    return throwError(err);
 }

    load() {
        const jsonFile = "assets/appsetting.json";

        return new Promise<void>((resolve, reject) => {
        	console.log("cargando configuración...");
            this.http.get(jsonFile).toPromise().then((response : Response) => {
               AppSettingsService.settings = <AppSettings>response.json();
               console.log("settings: "+AppSettingsService.settings);
               resolve();
            }).catch((response: any) => {
                console.log("Error leyendo archivo de configuración");
                this.onError(reject);
            });
        });

    } 

}