import { Injectable } from '@angular/core';
import { MessageService } from './message.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';



@Injectable({
  providedIn: 'root'
})
export class FormaterService {

  constructor(private messageService: MessageService) { }

  formatEpochToDate(dateEpoch:string):string {
	var dia;
  	var mes;
  	var fecha;

  	try {
  		var fechaAux = new Date(dateEpoch);
       	if (fechaAux.getDate() < 10) {
    		dia = '0'+fechaAux.getDate();
    	}else {dia = fechaAux.getDate();}    	 
    	 
       	if (fechaAux.getMonth() < 9) {
       		mes = (fechaAux.getMonth()+1);
       		mes = '0'+mes;
    	}else { mes = (fechaAux.getMonth()+1);}
       	fecha = dia+'-'+mes+'-'+fechaAux.getFullYear();
  	} catch (error) {
  		this.messageService.add("Error al formatear fecha:" + dateEpoch);
  	}
    
  	return fecha;
  }

  formatDateToYYYYMMDD(date: NgbDateStruct): string {
    return date ?
        `${date.year}${this.isNumber(date.month) ? this.padNumber(date.month) : ''}${this.isNumber(date.day) ? this.padNumber(date.day) : ''}`:'';
      }

	toInteger(value: any): number {
  		return parseInt(`${value}`, 10);
	}

  isNumber(value: any): value is number {
  	return !isNaN(this.toInteger(value));
	}

 padNumber(value: number) {
  if (this.isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}	


}
