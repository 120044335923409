<ul class="menu">
    <li>
        <a routerLink="/login" title="Iniciar Sesión" class="menu_item menu_item--icon" [class.selected]="'login' === selectedMenu" (click)="onSelect('login')">
        	<fa name="user" animation="false" (click)="onSelect('login')"></fa>
       	</a>
    </li>
    <li *ngIf="isLogged">
        <a routerLink="/position" title="Reporte de posición" class="menu_item" [class.selected]="'posicion' === selectedMenu" (click)="onSelect('posicion')">
        	Posición
		</a>
        <a routerLink="/flows" title="Reporte de flujos" class="menu_item" [class.selected]="'flujos' === selectedMenu" (click)="onSelect('flujos')">
            Flujos
        </a>

    </li>
</ul>